<script setup>
import {MagnifyingGlassIcon} from "@radix-icons/vue";
</script>

<template>
  <v-container class="nav-content desktop no-padding-margin">
    <v-row no-gutters
           justify="start"
           align="center"
           class="item top"
    >
      <v-col no-gutters>
        <UserNavigationComponent/>
      </v-col>
    </v-row>
    <v-row no-gutters justify="start" align="center" class="item">
      <v-col no-gutters></v-col>
    </v-row>
    <v-row
        no-gutters
        justify="start"
        align="center"
        class="item"
    >
      <v-col no-gutters class="flex-grow-0 flex-shrink-0">
        <span
            class="item link"
            @click="goToRoute('app-dashboard')"
        >
          Dashboard
        </span>
      </v-col>
    </v-row>
    <v-row
        no-gutters
        justify="start"
        align="center"
        class="item"
    >
      <v-col no-gutters class="flex-grow-0 flex-shrink-0">
        <span
            class="item link"
            @click="goToRoute('app-settings')"
        >
          {{ $t('nav.settings') }}
        </span>
      </v-col>
    </v-row>
    <v-row
        v-if="permissions.permissionLevel >= 2"
        no-gutters
        justify="start"
        align="center"
        class="item"
    >
      <v-col no-gutters class="flex-grow-0 flex-shrink-0">
        <span
            class="item link"
            @click="goToRoute('app-admin-dashboard')"
        >
          Admin Dashboard
        </span>
        <v-container>
          <v-row>
            <v-col class="no-y-padding top-spacer--s">
              <span
                  class=" item link secondary"
                  @click="goToRoute('app-admin-course-list')"
              >
                {{ $t('nav.courses') }}
              </span>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
    <v-row
        no-gutters
        @click="expandSearch = !expandSearch"
        justify="start"
        align="center"
        class="search-item top link search"
    >
      <v-col no-gutters class="flex-grow-0 flex-shrink-0">
        <MagnifyingGlassIcon class=" icon"/>
      </v-col>
    </v-row>
    <v-row
        no-gutters
        justify="start"
        align="center"
        class="search-item bottom left-spacer search-container">
      <v-col no-gutters>
        <SearchComponent @search="emitSearch"/>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import UserNavigationComponent from "./UserNavigationComponent.vue";
import SearchComponent from "@/components/data/SearchComponent.vue";
import {get} from "@/utils/localStorage.js";

export default {
  components: {
    UserNavigationComponent,
    SearchComponent
  },
  data() {
    return {
      expandSearch: false,
      permissions: get("permissions")
    }
  },
  methods: {
    goToRoute(routeName) {
      this.$router.push({name: routeName});
    },
    emitSearch(e = "") {
      this.$emit("search", e);
    }
  },
  watch: {
    expandSearch() {
      if (this.expandSearch) {
        document.querySelector('.nav-container.desktop')?.classList.add('expand-search');
        document.querySelector('.nav-container.desktop .search-container')?.classList.add('expand-search');
      } else {
        document.querySelector('.nav-container.desktop')?.classList.remove('expand-search');
        document.querySelector('.nav-container.desktop .search-container')?.classList.remove('expand-search');
      }
    }
  }
};
</script>
<style lang="scss">
.nav-container.desktop {
  max-width: calc(7rem + 7vw + 7vh);
  transition: max-width .3s ease-out;
}

.nav-container.desktop.expand-search {
  max-width: calc(9rem + 9vw + 9vh);
}

.nav-container.desktop .search-container.expand-search {
  width: 100%;
}

.nav-container.desktop .search-container {
  transition: width .3s ease-out;
  overflow: hidden;
  width: 0;
}

.nav-container.desktop .search-container .search-bar {
  max-width: calc(6rem + 6vw + 6vh);
}

.nav-content.desktop {
  height: 100vh;
}

.nav-content.desktop > .item {
  padding-left: calc(.35rem + 1vw + .45vh);
  padding-top: calc(.25rem + .25vw + .25vh);
  padding-bottom: calc(.25rem + .25vw + .25vh);
}

.nav-content.desktop > .item.top {
  padding-top: calc(.75rem + .75vw + .75vh);
}

.nav-content.desktop > .search-item.top {
  padding-left: calc(.35rem + 1vw + .45vh);
  padding-top: calc(.25rem + .25vw + .25vh);
}

.nav-content.desktop > .search-item.bottom {
  padding-left: calc(.35rem + 1vw + .45vh);
  padding-bottom: calc(.25rem + .25vw + .25vh);
}
</style>
