/* eslint-disable */
import {createRouter, createWebHistory} from "vue-router";
import LandingView from "../views/landing/EncouragingAcademyView.vue";
import {auth0} from "@/modules/auth0";
import {getFromServer} from "@/utils/fetcher";
import {get, set} from "@/utils/localStorage.js";
import config from "./config.json";

const routes = [
    {
        path: "/",
        name: "landing",
        component: LandingView
    },
    {
        path: "/newsletter",
        name: "newsletter",
        component: () =>
            import(/* webpackChunkName: "about" */ "@/modules/newsletter/NewsletterVerificationView.vue"),
    },
    {
        path: "/dashboard",
        name: "app-dashboard",
        component: () =>
            import(/* webpackChunkName: "dashboard" */ "../views/app/dashboard/DashboardView.vue"),
    },
    {
        path: "/store",
        name: "app-store",
        component: () =>
            import(/* webpackChunkName: "store" */ "@/modules/store/StoreView.vue"),
    },
    {
        path: "/settings",
        name: "app-settings",
        component: () =>
            import(
                /* webpackChunkName: "settings" */ "../views/app/settings/SettingsView.vue"
                ),
    },
    {
        path: "/store/details",
        name: "app-store-details",
        component: () =>
            import(
                /* webpackChunkName: "store details" */ "@/modules/store/details/StoreDetailsView.vue"
                ),
    },
    {
        path: "/store/purchase/checkout",
        name: "app-checkout",
        component: () =>
            import(
                /* webpackChunkName: "purchase checkout" */ "@/modules/store/purchase/CheckoutView.vue"
                ),
    },
    {
        path: "/course/dashboard",
        name: "course-dashboard",
        component: () =>
            import(
                /* webpackChunkName: "course dashboard" */ "@/modules/course/dashboard/CourseDashboardView.vue"
                ),
    },
    {
        path: "/course/entity",
        name: "course-entity",
        component: () =>
            import(
                /* webpackChunkName: "course entity" */ "@/modules/course/entities/CourseEntityView.vue"
                ),
    },
    {
        path: "/course/entity/finished",
        name: "course-entity-finished",
        component: () =>
            import(
                /* webpackChunkName: "course entity" */ "@/modules/course/entities/CourseEntityFinishedView.vue"
                ),
    },
    {
        path: "/course/notebook",
        name: "course-notebook",
        component: () =>
            import(
                /* webpackChunkName: "course notebook" */ "@/modules/course/notebook/NotebookView.vue"
                ),
    },
    {
        path: "/admin/dashboard",
        name: "app-admin-dashboard",
        component: () =>
            import(/* webpackChunkName: "admin dashboard" */ "../views/app/adminDashboard/AdminDashboardView.vue"),
    },
    {
        path: "/admin/course/list",
        name: "app-admin-course-list",
        component: () =>
            import(/* webpackChunkName: "admin course list" */ "@/modules/course/admin/CourseListView.vue"),
    },
    {
        path: "/admin/course/builder",
        name: "app-admin-course-builder",
        component: () =>
            import(/* webpackChunkName: "admin course builder" */ "@/modules/course/admin/builder/CourseBuilderView.vue"),
    },
    {
        path: "/no-access",
        name: 'no-access',
        component: () => import(/* webpackChunkName: "error" */ '../views/ErrorView.vue'),
        props: {
            code: 403,
            message: "Forbidden."
        }
    },
    {
        path: "/:pathMatch(.*)*",
        name: 'not-found',
        component: () => import(/* webpackChunkName: "error" */ '../views/ErrorView.vue'),
        props: {
            code: 404,
            message: "We could not find this page."
        }
    }
];

const router = createRouter({
    history: createWebHistory("/"),
    routes,
});

router.beforeEach(async (to, from, next) => {
    const permissions = get("permissions");

    /**
     * App routes are locked for permission level under 0.
     */
    if (config.app.includes(to.path)) {

        /**
         * Waits for Auth0 to finish loading.
         */
        while (auth0.isLoading.value) {
            await new Promise(resolve => setTimeout(resolve, 10))
        }

        /**
         * Checks if locally stored permissions exist and are valid. Else fetches new permissions.
         */
        if (!permissions || permissions.email !== auth0.user.value.email) {
            await getFromServer("/api/user/roles", (res) => {
                if (res.success && res.res.permissionLevel >= 0) {
                    set("permissions", {
                        email: auth0.user.value.email,
                        roles: res.res.roles,
                        permissionLevel: res.res.permissionLevel
                    });

                    next();
                }
                else next({ name: 'no-access' });
            });
        }
        else if (permissions.permissionLevel >= 0) next();
        else next({ name: 'no-access' });
    }

    else next();
});

export default router;
